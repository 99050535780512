export default [
    {
        "model": "CS35PLUS, I",
        "color": "Красный",
        "unique_id": "4679647",
        "price": "2099900",
        "currency": "RUR",
        "modification": "1.6 AT (117 л.с.)",
        "year": "2022",
        "vin": "LS5A3AEE5PA960051",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I",
        "color": "Белый",
        "unique_id": "4679605",
        "price": "2159900",
        "currency": "RUR",
        "modification": "1.6 AT (117 л.с.)",
        "year": "2022",
        "vin": "LS5A3DEE3PA960549",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I",
        "color": "Белый",
        "unique_id": "4679607",
        "price": "2159900",
        "currency": "RUR",
        "modification": "1.6 AT (117 л.с.)",
        "year": "2022",
        "vin": "LS5A3DEE1PA960551",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I",
        "color": "Красный",
        "unique_id": "4679645",
        "price": "2159900",
        "currency": "RUR",
        "modification": "1.6 AT (117 л.с.)",
        "year": "2022",
        "vin": "LS5A3DEE5PA960634",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Белый",
        "unique_id": "4679648",
        "price": "2379900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2022",
        "vin": "LS5A3DKR1PA970908",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4679653",
        "price": "2379900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2022",
        "vin": "LS5A3DKR2PA970870",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Серебряный",
        "unique_id": "4836101",
        "price": "2379900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR1PA001979",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Серебряный",
        "unique_id": "4836105",
        "price": "2379900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR7PA002005",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55, I",
        "color": "Красный",
        "unique_id": "4699575",
        "price": "2389900",
        "currency": "RUR",
        "modification": "1.5 AT (143 л.с.)",
        "year": "2022",
        "vin": "LS5A3AEE3PA940039",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55, I",
        "color": "Белый",
        "unique_id": "4836227",
        "price": "2389900",
        "currency": "RUR",
        "modification": "1.5 AT (143 л.с.)",
        "year": "2023",
        "vin": "LS5A3AEEXPA250076",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55, I",
        "color": "Черный",
        "unique_id": "4836228",
        "price": "2389900",
        "currency": "RUR",
        "modification": "1.5 AT (143 л.с.)",
        "year": "2023",
        "vin": "LS5A3AEE3PA250114",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Белый",
        "unique_id": "4679649",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2022",
        "vin": "LS5A3DKR5PA971205",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Красный",
        "unique_id": "4679650",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2022",
        "vin": "LS5A3DKR3PA970778",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Красный",
        "unique_id": "4679651",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2022",
        "vin": "LS5A3DKR3PA970991",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Красный",
        "unique_id": "4679652",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2022",
        "vin": "LS5A3DKRXPA970972",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4679654",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2022",
        "vin": "LS5A3DKR2PA971176",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Серебряный",
        "unique_id": "4679656",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2022",
        "vin": "LS5A3DKR0PA971046",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Серебряный",
        "unique_id": "4679657",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2022",
        "vin": "LS5A3DKR5PA971009",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Синий",
        "unique_id": "4679658",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2022",
        "vin": "LS5A3DKR0PA970821",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Синий",
        "unique_id": "4679659",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2022",
        "vin": "LS5A3DKR0PA970950",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Синий",
        "unique_id": "4679660",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2022",
        "vin": "LS5A3DKRXPA970924",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Белый",
        "unique_id": "4836081",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR0PA004744",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Белый",
        "unique_id": "4836082",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR2PA004633",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Белый",
        "unique_id": "4836083",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR2PA004681",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Белый",
        "unique_id": "4836084",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR3PA004351",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Белый",
        "unique_id": "4836085",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR3PA004401",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Белый",
        "unique_id": "4836086",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR3PA004415",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Белый",
        "unique_id": "4836087",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR3PA004589",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Белый",
        "unique_id": "4836088",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR3PA004642",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Белый",
        "unique_id": "4836089",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR3PA004737",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Белый",
        "unique_id": "4836090",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR3PA006245",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Красный",
        "unique_id": "4836091",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR0PA001391",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Красный",
        "unique_id": "4836092",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR0PA001844",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Красный",
        "unique_id": "4836093",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR1PA003960",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4836094",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR1PA000315",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4836095",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR1PA000881",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4836096",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR4PA004827",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4836097",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR4PA004858",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4836098",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR5PA004500",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Серебряный",
        "unique_id": "4836099",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR0PA004663",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Серебряный",
        "unique_id": "4836100",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR0PA004906",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Серебряный",
        "unique_id": "4836102",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR1PA004672",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Серебряный",
        "unique_id": "4836103",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR2PA004230",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Серебряный",
        "unique_id": "4836104",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR2PA004891",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Серебряный",
        "unique_id": "4836106",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR7PA004093",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Серебряный",
        "unique_id": "4836107",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR7PA004160",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Синий",
        "unique_id": "4836108",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR6PA000276",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Синий",
        "unique_id": "4836109",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR7PA973005",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Синий",
        "unique_id": "4836110",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR9PA000272",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Синий",
        "unique_id": "4836111",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKRXPA001348",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS35PLUS, I Рестайлинг",
        "color": "Черный",
        "unique_id": "4836112",
        "price": "2429900",
        "currency": "RUR",
        "modification": "1.4 AMT (150 л.с.)",
        "year": "2023",
        "vin": "LS5A3DKR2PA003496",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4836136",
        "price": "2539900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E7PA261412",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4836122",
        "price": "2599900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E0PA257279",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4836129",
        "price": "2599900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E1PA257193",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4836130",
        "price": "2599900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E2PA257235",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4836134",
        "price": "2599900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E3PA944868",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4836135",
        "price": "2599900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E4PA257222",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4836140",
        "price": "2599900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E7PA261572",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4836141",
        "price": "2599900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2EXPA276583",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Черный",
        "unique_id": "4836144",
        "price": "2599900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E2PA259342",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Белый",
        "unique_id": "4836113",
        "price": "2659900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E0PA277239",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Белый",
        "unique_id": "4836114",
        "price": "2659900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E0PA277242",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Белый",
        "unique_id": "4836115",
        "price": "2659900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E1PA276634",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Красный",
        "unique_id": "4836116",
        "price": "2659900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E0PA276849",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Красный",
        "unique_id": "4836117",
        "price": "2659900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E1PA276567",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Красный",
        "unique_id": "4836118",
        "price": "2659900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E1PA276679",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Красный",
        "unique_id": "4836119",
        "price": "2659900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E1PA276844",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Красный",
        "unique_id": "4836120",
        "price": "2659900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E2PA267957",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Красный",
        "unique_id": "4836121",
        "price": "2659900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E2PA269532",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4836123",
        "price": "2659900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E0PA269240",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4836124",
        "price": "2659900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E0PA269593",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4836125",
        "price": "2659900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E0PA269609",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4836126",
        "price": "2659900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E0PA276706",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4836127",
        "price": "2659900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E0PA276821",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4836128",
        "price": "2659900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E0PA276947",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4836131",
        "price": "2659900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E3PA276893",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4836132",
        "price": "2659900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E3PA277140",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4836133",
        "price": "2659900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E3PA277221",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4836137",
        "price": "2659900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E0PA267973",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4836138",
        "price": "2659900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E0PA269190",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4836139",
        "price": "2659900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E0PA270386",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Черный",
        "unique_id": "4836142",
        "price": "2659900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E0PA260960",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS55PLUS, I Рестайлинг",
        "color": "Черный",
        "unique_id": "4836143",
        "price": "2659900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2E1PA261132",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS75, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4601557",
        "price": "2689900",
        "currency": "RUR",
        "modification": "1.8 AT (150 л.с.) 4WD",
        "year": "2022",
        "vin": "LS4ASE2W5NJ101017",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS75, I Рестайлинг",
        "color": "Черный",
        "unique_id": "4699572",
        "price": "2689900",
        "currency": "RUR",
        "modification": "1.8 AT (150 л.с.) 4WD",
        "year": "2022",
        "vin": "LS4ASE2W6PJ100106",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "UNI-V",
        "color": "Белый",
        "unique_id": "4836160",
        "price": "2729900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS5A2DKE0PA281944",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "UNI-V",
        "color": "Белый",
        "unique_id": "4836161",
        "price": "2729900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS5A2DKE2PA276180",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "UNI-V",
        "color": "Серый",
        "unique_id": "4836162",
        "price": "2729900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS5A2DKE1PA274873",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "UNI-V",
        "color": "Серый",
        "unique_id": "4836163",
        "price": "2729900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS5A2DKE1PA274887",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "UNI-V",
        "color": "Серый",
        "unique_id": "4836164",
        "price": "2729900",
        "currency": "RUR",
        "modification": "1.5 AMT (181 л.с.)",
        "year": "2023",
        "vin": "LS5A2DKE1PA274890",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS75, I Рестайлинг",
        "color": "Белый",
        "unique_id": "4601551",
        "price": "2749900",
        "currency": "RUR",
        "modification": "1.8 AT (150 л.с.) 4WD",
        "year": "2022",
        "vin": "LS4ASE2W6NJ100913",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS75, I Рестайлинг",
        "color": "Белый",
        "unique_id": "4679613",
        "price": "2749900",
        "currency": "RUR",
        "modification": "1.8 AT (150 л.с.) 4WD",
        "year": "2022",
        "vin": "LS4ASE2W4PJ100203",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS75, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4679615",
        "price": "2749900",
        "currency": "RUR",
        "modification": "1.8 AT (150 л.с.) 4WD",
        "year": "2022",
        "vin": "LS4ASE2W9PJ100505",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS75, I Рестайлинг",
        "color": "Черный",
        "unique_id": "4679618",
        "price": "2749900",
        "currency": "RUR",
        "modification": "1.8 AT (150 л.с.) 4WD",
        "year": "2022",
        "vin": "LS4ASE2WXPJ100447",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS75, I Рестайлинг",
        "color": "Белый",
        "unique_id": "4699576",
        "price": "2749900",
        "currency": "RUR",
        "modification": "1.8 AT (150 л.с.) 4WD",
        "year": "2022",
        "vin": "LS4ASE2W5PJ100811",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS75, I Рестайлинг",
        "color": "Белый",
        "unique_id": "4699577",
        "price": "2749900",
        "currency": "RUR",
        "modification": "1.8 AT (150 л.с.) 4WD",
        "year": "2022",
        "vin": "LS4ASE2W6PJ100820",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS75, I Рестайлинг",
        "color": "Красный",
        "unique_id": "4699580",
        "price": "2749900",
        "currency": "RUR",
        "modification": "1.8 AT (150 л.с.) 4WD",
        "year": "2022",
        "vin": "LS4ASE2W5PJ101358",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS75, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4699581",
        "price": "2749900",
        "currency": "RUR",
        "modification": "1.8 AT (150 л.с.) 4WD",
        "year": "2022",
        "vin": "LS4ASE2W3PJ100564",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS75, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4699582",
        "price": "2749900",
        "currency": "RUR",
        "modification": "1.8 AT (150 л.с.) 4WD",
        "year": "2022",
        "vin": "LS4ASE2W4PJ100606",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS75, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4699583",
        "price": "2749900",
        "currency": "RUR",
        "modification": "1.8 AT (150 л.с.) 4WD",
        "year": "2022",
        "vin": "LS4ASE2WXPJ100612",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS75, I Рестайлинг",
        "color": "Черный",
        "unique_id": "4837229",
        "price": "2749900",
        "currency": "RUR",
        "modification": "1.8 AT (150 л.с.) 4WD",
        "year": "2023",
        "vin": "LS4ASE2W8PJ100429",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS85",
        "color": "Серый",
        "unique_id": "4836145",
        "price": "3509900",
        "currency": "RUR",
        "modification": "2.0 AT (224 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2A0PP151277",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS85",
        "color": "Серый",
        "unique_id": "4836146",
        "price": "3509900",
        "currency": "RUR",
        "modification": "2.0 AT (224 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2A0PP151392",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS85",
        "color": "Черный",
        "unique_id": "4836147",
        "price": "3509900",
        "currency": "RUR",
        "modification": "2.0 AT (224 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2A0PP151585",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS95, I Рестайлинг",
        "color": "Белый",
        "unique_id": "4836148",
        "price": "4049900",
        "currency": "RUR",
        "modification": "2.0 AT (220 л.с.) 4WD",
        "year": "2023",
        "vin": "LS4ASE2A2PJ103654",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS95, I Рестайлинг",
        "color": "Серый",
        "unique_id": "4836149",
        "price": "4049900",
        "currency": "RUR",
        "modification": "2.0 AT (220 л.с.) 4WD",
        "year": "2023",
        "vin": "LS4ASE2A2PJ103542",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "CS95, I Рестайлинг",
        "color": "Черный",
        "unique_id": "4836150",
        "price": "4049900",
        "currency": "RUR",
        "modification": "2.0 AT (220 л.с.) 4WD",
        "year": "2023",
        "vin": "LS4ASE2A3PJ103596",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "UNI-K",
        "color": "Белый",
        "unique_id": "4836154",
        "price": "4700000",
        "currency": "RUR",
        "modification": "2.0 AT (226 л.с.)",
        "year": "2023",
        "vin": "LS4ASE2A7PA703408",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "UNI-K",
        "color": "Серый",
        "unique_id": "4836157",
        "price": "4850000",
        "currency": "RUR",
        "modification": "2.0 AT (226 л.с.) 4WD",
        "year": "2023",
        "vin": "LS4ASE2A4PA703267",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "UNI-K",
        "color": "Серый",
        "unique_id": "4836158",
        "price": "4850000",
        "currency": "RUR",
        "modification": "2.0 AT (226 л.с.) 4WD",
        "year": "2023",
        "vin": "LS4ASE2A8PA703398",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "UNI-K",
        "color": "Белый",
        "unique_id": "4836151",
        "price": "5000000",
        "currency": "RUR",
        "modification": "2.0 AT (226 л.с.) 4WD",
        "year": "2023",
        "vin": "LS4ASE2A3PA704698",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "UNI-K",
        "color": "Белый",
        "unique_id": "4836152",
        "price": "5000000",
        "currency": "RUR",
        "modification": "2.0 AT (226 л.с.) 4WD",
        "year": "2023",
        "vin": "LS4ASE2A3PA704779",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "UNI-K",
        "color": "Белый",
        "unique_id": "4836153",
        "price": "5000000",
        "currency": "RUR",
        "modification": "2.0 AT (226 л.с.) 4WD",
        "year": "2023",
        "vin": "LS4ASE2A5PA704881",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "UNI-K",
        "color": "Серый",
        "unique_id": "4836155",
        "price": "5000000",
        "currency": "RUR",
        "modification": "2.0 AT (226 л.с.) 4WD",
        "year": "2023",
        "vin": "LS4ASE2A1PA704828",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "UNI-K",
        "color": "Серый",
        "unique_id": "4836156",
        "price": "5000000",
        "currency": "RUR",
        "modification": "2.0 AT (226 л.с.) 4WD",
        "year": "2023",
        "vin": "LS4ASE2A3PA705088",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    },
    {
        "model": "UNI-K",
        "color": "Синий",
        "unique_id": "4836159",
        "price": "5000000",
        "currency": "RUR",
        "modification": "2.0 AT (226 л.с.) 4WD",
        "year": "2023",
        "vin": "LS4ASE2A0PA703668",
        "images": "https://sale.changan-kuntsevo.ru/back/images/LS5A3DEE3PA960549",
        "max_discount": 1060000
    }
]