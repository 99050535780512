<template>
  <footer class="footer">
    <div class="footer__container container">
      <div class="footer__top">
        <img 
          class="footer__dealer"
          src="../images/logos/dealer.svg"
        >
        <div class="footer__logo logo"></div>

        <div class="footer__links">
          <a
            href="https://www.youtube.com/channel/UCzaqpxhGDGkTmjKc6FmDRcg?view_as=subscriber"
            target="_blank"
          >
            <img 
              src="../images/footer/youtube.svg" 
              alt="Иконка для ссылки на ютуб"
            >
          </a>

          <a 
            href="https://t.me/tckuntsevo"
            target="_blank"
          >
            <img 
              src="../images/footer/tg.svg" 
              alt="Иконка для ссылки на телеграм"
            >
          </a>
        </div>
      </div>

      <div class="footer__yur-info">
        ООО «Кунцево Авто». Юр. Адрес: 143025, Московская область, г. Одинцово, с.Немчиновка, ул. Московская, д.61, ком.405. Факт. Адрес: г.Москва, ул. Горбунова, д.14. Телефон: <a :href="'tel:' + CONSTANTS.offices[0].phone_raw" class="phone">{{CONSTANTS.offices[0].phone}}</a>. ОГРН – 1135032010300. ИНН – 5032271845
      </div>

      <div class="footer__bottom">
        <div class="footer__copyright">© Changan Automobile Group, 2023</div>

        <div class="footer__more-btn" @click="expanded = !expanded">Подробные условия акции</div>

        <div class="footer__policy-btn">Политика конфиденциальности</div>
      </div>

      <div class="footer__dis" v-show="expanded">
        *Процентная ставка варьируется от 0,01% до 16,5% годовых и зависит от суммы первоначального взноса, оформления страхования, размера и срока автокредита Чанган.
        <br>
        Обращаем ваше внимание на то, что вся представленная на сайте информация, в том числе касающаяся акций, комплектаций, технических характеристик, цветовых сочетаний, а также стоимости автомобилей и сервисного обслуживания, носит информативный характер и ни при каких условиях не является публичной офертой, определяемой положениями ст.437 Гражданского кодекса Российской Федерации. 
        Для получения подробной информации, пожалуйста, обращайтесь по тел.: <a :href="'tel:' + CONSTANTS.offices[0].phone_raw" class="phone">{{CONSTANTS.offices[0].phone}}</a>
        <br>© 2023 «ТЦ Кунцево» - официальный дилер Changan.
      </div>
    </div>
  </footer>
</template>

<script>
    import Mixin from '../common/mixin';

    export default {
        name: "block-footer",
        mixins: [Mixin],
        data() {
            return {
              expanded: false,
              socials: [
                // {id: 1, icon: 'instagram.png', link: 'https://www.instagram.com/changan_kuntsevo/'},
                {id: 2, icon: 'youtube.png', link: 'https://www.youtube.com/channel/UCzaqpxhGDGkTmjKc6FmDRcg?view_as=subscriber'},
                {id: 3, icon: 'telegram.png', link: 'https://t.me/tckuntsevo'},
              ]
            }
        }
    }
</script>

<style scoped lang="scss">
  @import "../styles/constants.scss";

  .footer {
    color: #fff;
    font-size: 15px;
    background: $black;
    width: 100%;
    padding: 65px 0;

    &__top {
      display: flex;
      align-items: center;
      margin-bottom: 60px;
    }

    &__logo {
      width: 242px;
      height: 33px;
      margin-right: auto;
    }

    &__dealer {
      width: 250px;
      margin-right: 20px;
      padding-right: 20px;
      border-right: 2px solid #fff;
    }

    &__links {
      display: flex;
      a {
        &:not(:last-child) {
          margin-right: 29px;
        }
      }
    }


    &__yur-info {
      font-size: 12px;
      margin-bottom: 35px;
    }

    &__bottom {
      display: flex;
    }

    &__more-btn {
      margin: 0 auto;
      text-decoration: underline;
      cursor: pointer;
    }

    &__policy-btn {
      text-decoration: underline;
      cursor: pointer;
    }

    &__dis {
      margin-top: 20px;
    }
  }

  @media (max-width: 800px) {
    .footer {
      color: #FFFFFF9E;
      padding: 25px 0;
      font-size: 10px;

      &__top {
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 31px;
      }

      &__dealer {
        width: 120px;
        padding-right: 13px;
        margin-right: 13px;
      }

      &__logo {
        width: 116px;
        height: 16px;
        margin-right: 0;
      }

      &__links {
        width: 100%;
        margin-top: 27px;
        justify-content: center;

        a {

          img {
            width: 80%;
          }

          &:not(:last-child) {
            margin-right: 0;
          }
        }
      }

      &__yur-info {
        font-size: 10px;
        margin-bottom: 12px;
      }

      &__bottom {
        flex-direction: column;
        align-items: center;
      }

      &__copyright {
        margin-bottom: 16px;
        order: 1;
      }

      &__policy-btn {
        margin-bottom: 16px;
        order: 2;
      }

      &__more-btn {
        order: 3;
      }
    }
  }
</style>
