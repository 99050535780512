<template>
  <section class="offer">
    <div class="offer__container container">
			<div class="offer__title">
				Получить специальное<br> предложение <b>от КУНЦЕВО</b>
			</div>

			<callback-form
				:button_class="'btn_white'"
				:horizontal="true"
				:checkbox_white="true"
				@callBack="handleCallBack"
			>Заказать звонок</callback-form>
    </div>
  </section>
</template>

<script>
import CallbackForm from './callback-form/callback-input.vue';
import Mixin from '../common/mixin';

export default {
	name: 'block-offer',
	mixins: [Mixin],
	components: { CallbackForm }
}
</script>

<style lang="scss" scoped>
@import "../styles/constants.scss";


.offer {
	color: #fff;
	background: url('../images/new-bg/offer1.png') no-repeat left/contain, url('../images/new-bg/offer2.png') no-repeat right/contain;
	background-color: $black;
	width: 100%;
	padding: 77px 0;

	&__container {
		display: flex;
		align-items: center;
	}

	&__title {
		font-size: 25px;
		margin-right: auto;
	}
}

@media (max-width: 800px) {
	.offer {
		background: $black;
		padding: 20px 0;

		&__container {
			flex-direction: column;
			align-items: stretch;
		}

		&__title {
			font-size: 14px;
			margin-bottom: 17px;
		}
	}
}
</style>