// Отправка данных в яндекс-метрику
const yandexId = '95815525'  // id скрипта
const isSendOnlyOnce = false  // отправлять только 1 раз за визит

export default 	function(goal, oneTimeSending = isSendOnlyOnce) {
	if(!yandexId) {
		return console.warn('Необходимо указать id скрипта метрики!')
	}

	if (window.ym) {
		if (oneTimeSending) {
			// отправка только 1 раз за ВИЗИТ
			if(!sessionStorage.getItem(goal)) {
				window.ym(yandexId, 'reachGoal', goal)
				// запрет на отправку в течении сессии
				sessionStorage.setItem(goal, 'true')
			}
		} else {
			//	Безграничное количество отправок
			window.ym(yandexId, 'reachGoal', goal)
		}

	} else {
		console.log('Метрика не подключена!')
	}
}



