<template>
  <section class="block-main">
    <img
      class="block-main__mobile-banner"
      src="../images/new-bg/main_m.jpg" 
      alt="Баннер"
    >

    <div class="block-main__container container">
      <h1 class="block-main__title">
        <p>АКЦИЯ НА CHANGAN <br/>В НАЛИЧИИ В КУНЦЕВО</p>
        <p>Выгоды до <b>1 400 000 ₽</b> <br>+ Рассрочка <b>0%</b></p>
      </h1>
    </div>
  </section>
</template>

<script>
import CallbackInput from "./callback-form/callback-input";
import Mixin from "../common/mixin";
import finance from "../common/finance";

export default {
  name: "block-main-new",
  components: { CallbackInput },
  mixins: [Mixin, finance],
};
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";

.block-main {
  color: #fff;
  width: 100%;
  height: 900px;
  background: url('../images/new-bg/main.jpg') no-repeat 0% 75%/cover;
  // background: linear-gradient(222deg, rgba(36, 43, 49, 0.82) 35.42%, rgba(35, 47, 54, 0) 60%), url('../images/new-bg/main.jpg') no-repeat 0% 75%/cover;
  padding-top: 200px;
  position: relative;


  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__title {
    font-weight: normal;
    font-size: 46px;
    text-transform: uppercase;

    p {
      border-right: 3px solid #000;
      padding-right: 44px;
      text-align: right;
      color: #000;

      &:not(:last-child) {
        margin-bottom: 50px;
      }
    }
  }

  &__mobile-banner {
    display: none;
  }
}

@media (max-width: 800px) {
  .block-main {
    background: $black;
    padding-top: 0;
    height: auto;

    &__container {
      padding-top: 20px;
      align-items: flex-start;
    }

    &__title {
      font-size: 16px;

      p {
        text-align: left;
        padding-right: 0;
        border-right: none;
        padding-left: 14px;
        border-left: 1px solid #fff;

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }

    &__mobile-banner {
      display: flex;
      width: 100%;
      object-fit: cover;
      max-height: 400px;
      object-position: 50% 100%;
    }
  }
}
</style>
