<template>
    <video-player  class="video-player-box"
                   ref="videoPlayer"
                   :options="playerOptions"
                   :playsinline="true"
                   customEventName="customstatechangedeventname"

                  >
    </video-player>
</template>

<script>
    import $ from 'jquery';
    import video from '../video/video.mp4';
    import preview from '../video/preview.jpg';
    import preview_webp from '../video/preview.webp';

    export default {
        props: ['webp'],
        data() {
            return {
                playerOptions: {
                    muted: false,
                    language: 'rus',
                    playbackRates: [0.7, 1.0, 1.5, 2.0],
                    width: '980px',
                    sources: [{
                        type: "video/mp4",
                        src: video
                    }],
                    poster: this.webp ? preview_webp : preview,
                }
            }
        },
        mounted() {

            let handleResize = () => {
                if ($(window).width() <= 1000) {
                    this.playerOptions.width = $(window).width();
                } else  this.playerOptions.width = 980;
            };
            $(window).on('resize', handleResize);
            handleResize();
        },
        computed: {
            player() {
                return this.$refs.videoPlayer.player
            }
        },
        methods: {
            onPlayerPlay(player) {
                // console.log('player play!', player)
            },
            onPlayerPause(player) {
                // console.log('player pause!', player)
            },
            // ...player event

            // or listen state event
            playerStateChanged(playerCurrentState) {
                // console.log('player current update state', playerCurrentState)
            },

            // player is ready
            playerReadied(player) {

            }
        }
    }
</script>

<style scoped>


    .video-player-box {
        margin-top: 70px;
    }

    @media (max-width: 1215px) {
        .video-player-box {
            display: none;
        }
    }

    @media (max-width: 1000px) {

        .vjs_video_3-dimensions {
            width: 100vw;
        }

        #vjs_video_3 {
            width: 100vw;
        }

        .video-player-box {
            margin-top: 60px;
        }
    }
</style>
