<template>
  <section class="cars">
    <div class="cars__container container">
      <!-- <div class="cars__nav swiper-container">
        <div class="cars__nav-wrapper swiper-wrapper">
          <div
            class="cars__nav-item swiper-slide"
            v-for="car in cars"
            :key="car.id"
          >
            <img
              class="cars__nav-img"
              :src="getCarImg(car.id)"
              :alt="'Фото модели ' + car.name"
              @click="scrollTo(car.id)"
            >
            
            <div 
              class="cars__nav-title"
              @click="scrollTo(car.id)"
            >Changan {{ car.name }}</div>

            <button
              class="cars__nav-btn btn btn_primary"
              @click="getCall"
            >Узнать цену по акции</button>
          </div>
        </div>

        <div class="cars__nav-pagination"></div>

        <div class="cars__nav-next swiper-button-next"></div>
        <div class="cars__nav-prev swiper-button-prev"></div>
      </div> -->

      <div class="cars__list">
        <block-car
          class="cars__item" 
          v-for="car in filteredCars"
          :key="car.id"
          :id="car.id"
          :car="car"
          @getCall="getCall"
          @openGallery="openGallery"
        ></block-car>

        <button 
          v-if="!full_list"
          class="cars__show-more btn btn_primary"
          @click="showMore"
        >
          Показать другие модели
        </button>
      </div>

      <div 
        class="cars__gallery" 
        v-show="gallery"
      >
        <div class="cars__gallery-content">
          <div class="cars__gallery-counter" v-if="gallery_swiper">
            {{ 1 + gallery_swiper.activeIndex }} / {{ gallery_car.images }}
          </div>
          <img
            src="../images/utils/close.svg"
            alt="Закрыть"
            class="cars__gallery-swiper-close"
            @click="closeGallery"
          />

          <div class="cars__gallery-swiper swiper-container">
            <div class="cars__gallery-swiper-wrapper swiper-wrapper">
              <img 
                class="cars__gallery-swiper-slide swiper-slide"
                v-for="i in gallery_car.images"
                :key="i"
                :src="getGalleryImg(gallery_car.id, i)"
              >
            </div>

            <div class="cars__gallery-swiper-arrow cars__gallery-swiper-arrow-prev"></div>
            <div class="cars__gallery-swiper-arrow cars__gallery-swiper-arrow-next"></div>
          </div>

          <div class="cars__gallery-bottom-imgs">
            <img
              v-for="i in 4"
              :key="i"
              :src="getGalleryImg(gallery_car, i)"
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import cars from '../common/cars-info';
import Mixin from '../common/mixin';
import BlockCar from './block-car.vue';
import Swiper from 'swiper';

export default {
  components: { BlockCar },
  name: 'block-cars',
  mixins: [Mixin],
  data() {
    return {
      cars,
      shownCars: 5,
      nav_swiper: null,
      gallery_swiper: null,
      gallery: false,
      gallery_car: cars[4],
      full_list: false,
    }
  },
  computed: {
    filteredCars() {
      return this.full_list ? this.cars : this.cars.slice(0, this.shownCars);
    }
  },
  mounted() {
    this.initNavSwiper();
  },
  methods: {
    showMore() {
      this.full_list = true;
    },
    initNavSwiper() {
      this.nav_swiper = new Swiper('.cars__nav', {
        loop: true,
        slidesPerView: 5,
        spaceBetween: 35,
        navigation: {
          nextEl: '.cars__nav-next',
          prevEl: '.cars__nav-prev',
        },
        pagination: {
          el: '.cars__nav-pagination',
          type: 'bullets'
        }
      }) 
    },
    initGallerySwiper() {
      this.gallery_swiper = new Swiper('.cars__gallery-swiper', {
        slidesPerView: 1,
        observer: true,
        observeSlideChildren: true,
        observeParents: true,
        navigation: {
          nextEl: '.cars__gallery-swiper-arrow-next',
          prevEl: '.cars__gallery-swiper-arrow-prev',
        },
      }) 
    },
    openGallery(car) {
      if (car.images) {
        this.gallery = true;
        this.gallery_car = car;
        this.initGallerySwiper();
      }
    },
    closeGallery() {
      this.gallery = false;
    },
    getCarImg(car) {
      return require('../images/cars/nav/' + car + '.png');
    },
    getGalleryImg(car, index) {
      if (car.images) {
        return require('../images/cars/new/' + car.id + '/' +  index + '.jpg');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/constants.scss";

.cars {
  padding-top: 160px;
  padding-bottom: 150px;
  width: 100%;
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    width: 100%;
    height: 120px;
    background: $black;
    content: '';
  }

  &__nav {
    padding-left: 35px;
    padding-right: 35px;
    margin-bottom: 135px;
  }

  &__nav-wrapper {
    display: flex;
    box-sizing: border-box;
  }

  &__nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 320px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__nav-img {
    margin-bottom: 10px;
    cursor: pointer;
    width: 100%;
  }

  &__nav-title {
    font-weight: bold;
    font-size: 19px;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: auto;
    margin-bottom: 14px;
    cursor: pointer;

    &::before {
      height: 4px;
      width: 50px;
      background: $black;
      margin-bottom: 14px;
      content: '';
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__show-more {
    margin: 0 auto;
  }

  &__gallery {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0,0,0, .6);
    z-index: 300;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__gallery-content {
    position: relative;
    width: 95vw;
    max-width: 700px;
  }

  &__gallery-bottom-imgs {
    display: flex;
    img {
      width: 25%;
    }
  }

  &__gallery-swiper-close {
    position: absolute;
    top: 20px;
    right: 25px;
    z-index: 2;
    cursor: pointer;
  }

  &__gallery-counter {
    position: absolute;
    top: 20px;
    left: 25px;
    z-index: 2;
  }

  &__gallery-swiper-arrow {
    width: 35px;
    height: 35px;
    background: rgba(255, 255, 255, 0.62) url('../images/icons/gallery-arrow.svg') no-repeat center;
    position: absolute;
    z-index: 1;
    top: 48%;
    border-radius: 5px;
    cursor: pointer;

    &-prev {
      left: 22px;
      transform: rotate(-180deg);
    }

    &-next {
      right: 22px;
    }

    &.swiper-button-disabled {
      opacity: .6;
    }
  }
}

@media (max-width: 800px) {
  .cars {
    padding-top: 25px;
    padding-bottom: 47px;

    &::before {
      content: none;
    }

    &__container {
      padding-left: 0;
      padding-right: 0;
    }

    &__nav {
      display: none;
      margin-bottom: 30px;
    }

    &__nav-wrapper {
      box-sizing: content-box;
    }

    &__nav-item {
      width: 100%;
      height: auto;
      flex: initial;
      flex-shrink: 0;

      &:not(:last-child) {
        margin-right: 0;
      }
    }

    &__nav-title {
      color: #fff;
      order: 1;

      &::before {
        content: none;
      }
    }

    &__nav-img {
      display: flex;
      order: 2;
      max-width: 360px;
      margin-bottom: 30px;
    }

    &__nav-pagination {
      position: absolute;
      bottom: 55px !important;
      display: flex;
      justify-content: center;
    }

    &__nav-btn {
      order: 3;
    }

    &__gallery-swiper-close {
      top: 12px;
      right: 12px;
    }

    &__gallery-counter {
      top: 12px;
      left: 12px;
    }

    &__gallery-swiper-arrow {
      width: 28px;
      height: 28px;
      background-size: 45%;

      &-prev {
        left: 15px;
      }

      &-next {
        right: 15px;
      }
    }
  }
}
</style>

<style lang="scss">
@import "../styles/constants.scss";

.cars__nav-pagination {
  .swiper-pagination-bullet {
    height: 2px;
    width: 43px;
    background: #D1D1D1;
    transition: all 200ms linear;
    border-radius: 0;
    opacity: 1;

    &-active {
      width: 144px;
      background: $black;
    }
  }
}

.cars__nav-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000'%2F%3E%3C%2Fsvg%3E") !important;
  width: 20px !important;
  height: 30px !important;
  background-size: contain !important;
}

.cars__nav-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000'%2F%3E%3C%2Fsvg%3E") !important;
  width: 20px !important;
  height: 30px !important;
  background-size: contain !important;
}
</style>