<template>
	<label class="checkbox" :class="{white}">
		<input type="checkbox" class="checkbox__input" :checked="value" @change="change" />
		<span class="checkbox__box"></span>
		<slot></slot>
	</label>
</template>

<script>
export default {
	name: "CheckBox",
	emits: ['checkboxChange'],
	props: ["value", "white"],
	methods: {
		change() {
			this.$emit("checkboxChange");
		}
	},
};
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";

.checkbox {
	display: flex;
	align-items: center;
	cursor: pointer;

	&__input {
		position: absolute;
		appearance: none;
		-webkit-appearance: none;
	}

	&__input:checked + .checkbox__box::after {
		background: url("../images/utils/galka.svg") no-repeat center/contain;
		width: 12px;
		height: 12px;
		content: "";
	}

	&__box {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		width: 12px;
		height: 12px;
		border: 1px solid;
		margin-right: 8px;
	}

	&.white {
		.checkbox__input:checked + .checkbox__box::after {
			background-image: url("../images/utils/galka_white.svg");
		}
	}
}
</style>
