const btns = ["Узнать персональную цену"];

export default [
	{
		new: false,
		id: "cs55plus",
		name: "cs55plus",
		price: 1489900,
		advantage: 950000,
		credit_rate: "0",
		chars: [
			"<b>КПП:</b> 7DCT",
			"<b>Привод:</b> 2WD, передний",
			"<b>Двигатель:</b> 1.5Т, 181 л.с.",
		],
		btns,
		images: false,
	},

	{
		id: "alsvin",
		name: "Alsvin",
		new: false,
		price: false,
		advantage: 750000,
		credit_rate: "0",
		chars: [
			"<b>КПП:</b> 5DCT",
			"<b>Привод:</b> 2WD, передний",
			"<b>Двигатель:</b> 1.5L, 98 л.с.",
		],
		btns,
		images: false,
		label: false,
	},
	{
		new: false,
		id: "uni-t",
		name: "uni-t",
		price: false,
		advantage: 960000,
		credit_rate: "0",
		chars: [
			"<b>КПП:</b> 7DCT",
			"<b>Привод:</b> 2WD, передний",
			"<b>Двигатель:</b> 1.5Т, 167л.с.",
		],
		btns,
		images: false,
	},

	{
		id: "eado_plus",
		name: "Eado plus",
		new: false,
		price: false,
		advantage: 960000,
		credit_rate: "0",
		chars: [
			"<b>КПП:</b> 7DCT",
			"<b>Привод:</b> 2WD, передний",
			"<b>Двигатель:</b> 1.4L, 155 л.с.",
		],
		btns,
		images: false,
		label: false,
	},

	{
		new: false,
		id: "uni-v",
		name: "uni-v",
		price: 1489900,
		advantage: 900000,
		credit_rate: "0",
		chars: [
			"<b>КПП:</b> 7DCT",
			"<b>Привод:</b> 2WD, передний",
			"<b>Двигатель:</b> 1.5Т, 181л.с.",
		],
		btns,
		images: false,
	},
	{
		id: "lamore",
		name: "Lamore",
		new: false,
		price: 1759900,
		advantage: 950000,
		credit_rate: "0",
		chars: [
			"<b>КПП:</b>  7DCT",
			"<b>Привод:</b> 2WD, передний",
			"<b>Двигатель:</b> 1.5T, 181 л.с.",
		],
		btns,
		images: false,
		label: false,
	},
	{
		new: false,
		id: "cs95",
		name: "cs95",
		price: 1489900,
		advantage: 950000,
		credit_rate: "0",
		chars: [
			"<b>КПП:</b> 8AT",
			"<b>Привод:</b> 4WD, полный",
			"<b>Двигатель:</b> 2.0T, 226 л.с.",
		],
		btns,
		images: false,
	},
	{
		id: "uni-k",
		name: "uni-k",
		price: 1399900,
		advantage: 1400000,
		// dop: "Панорамная крыша и отделка сидений кожей Nappa",
		credit_rate: "0",
		chars: [
			"<b>КПП:</b> 8AT",
			"<b>Привод:</b>4WD, полный",
			"<b>Двигатель:</b> 2.0T, 226 л.с.",
		],
		btns,
		images: false,
	},

	// {
	// 	id: "cs75_plus",
	// 	name: "cs75 Plus",
	// 	new: true,
	// 	price: 1759900,
	// 	advantage: 460000,
	// 	credit_rate: "0",
	// 	chars: [
	// 		"<b>КПП:</b> 6AT",
	// 		"<b>Привод:</b> 2WD, передний",
	// 		"<b>Двигатель:</b> 1.5T, 167 л.с.",
	// 	],
	// 	btns,
	// 	images: false,
	// 	label: false,
	// },

	// {
	// 	id: "cs85_coupe",
	// 	name: "cs85 coupe",
	// 	new: true,
	// 	price: 1489900,
	// 	advantage: 710000,
	// 	credit_rate: "0",
	// 	chars: [
	// 		"<b>КПП:</b> 8АТ",
	// 		"<b>Привод:</b> 2WD, передний",
	// 		"<b>Двигатель:</b> 2.0Т, 224 л.с.",
	// 	],
	// 	btns,
	// 	images: false,
	// },

	{
		id: "hunter",
		name: "hunter plus",
		price: 1399900,
		advantage: 800000,
		// dop: "Панорамная крыша и отделка сидений кожей Nappa",
		credit_rate: "0",
		chars: [
			"<b>КПП:</b> 8AT",
			"<b>Привод:</b>4WD, полный",
			"<b>Двигатель:</b> 2.0T, 226 л.с.",
		],
		btns,
		images: false,
	},
	// {
	// 	id: "cs85",
	// 	name: "cs85",
	// 	new: true,
	// 	price: 1489900,
	// 	advantage: 700000,
	// 	credit_rate: "0",
	// 	chars: [
	// 		"<b>КПП:</b> 8АТ",
	// 		"<b>Привод:</b> 2WD, передний",
	// 		"<b>Двигатель:</b> 2.0Т, 224 л.с.",
	// 	],
	// 	btns: ["Записаться на тест-драйв", "Рассчитать кредит"],
	// 	images: false,
	// },
	// {
	// 	id: "cs55",
	// 	name: "cs55",
	// 	price: 1489900,
	// 	advantage: 450000,
	// 	credit_rate: "0",
	// 	chars: [
	// 		"<b>КПП:</b> 6АКПП",
	// 		"<b>Привод:</b> 2WD",
	// 		"<b>Двигатель:</b> 1.5T, 143 л.с.",
	// 	],
	// 	btns: ["Записаться на тест-драйв", "Рассчитать кредит"],
	// 	images: false,
	// },
];
