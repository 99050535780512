<template>
  <div class="block-clock-container" :class="[device_platform]">
      <div class="bclock-header">
        ПРЕДЛОЖЕНИЕ ОГРАНИЧЕНО!
      </div>
      <div class="bclock-clock">
        <div v-for="stat in date_parts" class="bclock-clock-stat" :key="stat.name">
          <div class="bclock-clock-stat__value">{{stat.value}}</div>
          <div class="bclock-clock-stat__name">{{stat.name}}</div>
        </div>
      </div>
  </div>
</template>

<script>
import Mixin from "../common/mixin";
import CF from "../common/common-functions";

export default {
  name: "block-clock",
  components: {},
  directives: {},
  mixins: [Mixin],
  props: [""],
  data() {
    return {
      date_parts: [
        {
          name: "Дней",
          units: "days",
          value: 0,
          period: 0,
        },
        {
          name: "Часов",
          units: "hours",
          value: 0,
          period: 24,
        },
        {
          name: "Минут",
          units: "minutes",
          value: 0,
          period: 60,
        },
        {
          name: "Секунд",
          units: "seconds",
          value: 0,
          period: 60,
        },
      ],
    };
  },
  created() {
    let small_part_multiplier = 1,
      time_left = (this.CONSTANTS.end_date.getTime() - Date.now()) / 1000;
    for (let i = this.date_parts.length - 1; i >= 0; --i) {
      let part = this.date_parts[i];
      part.value = Math.floor(
        (time_left / small_part_multiplier) % (part.period || 10000)
      );
      part.name = this.getName(part);
      small_part_multiplier = small_part_multiplier * part.period;
    }
    setInterval(this.clockTick, 1000);
  },
  computed: {},
  methods: {
    clockTick() {
      for (let i = this.date_parts.length - 1; i >= 0; --i) {
        if (this.date_parts[i].value > 0) {
          --this.date_parts[i].value;
          this.date_parts[i].name = this.getName(this.date_parts[i]);
          return;
        }
        this.date_parts[i].value = this.date_parts[i].period - 1;
        this.date_parts[i].name = this.getName(this.date_parts[i]);
      }
    },
    getName(date_part) {
      return CF.getNumericalName(date_part.value, date_part.units);
    },
  },
};
</script>

<style scoped lang="scss">

.block-clock-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 27px;
  color: #0B457F;
  padding: 10px;
  background: rgba(255,255,255, 0.5);
}


.bclock-header {
  font-weight: bold;
}

.bclock-clock {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.bclock-clock-stat {
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.bclock-clock-stat__value {
  font-weight: bold;
}

.bclock-clock-stat__name {
  font-size: 18px;
  font-weight: 400;
}

@media (max-width: 800px) {
  .block-clock-container {
    display: none;
  }
}

</style>
