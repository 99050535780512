<template>
    <div class="selectors-bar"
         :class="[device_platform]">
        <div class="selector"
             v-for="selector in values" :key="selector.name"
             :id="'sel-' + selector.name.toLowerCase()"
             :class="{active: selector.name === cur_value}"
             @click="selectModel(selector.name)"
             v-html="selector.text ? selector.text : selector.name"
        >
            {{}}
        </div>
    </div>
</template>

<script>
    import Mixin from "../common/mixin";

    export default {
        name: 'selectorBar',
        props: ['values', 'cur_value', 'b_cars_car'],
        mixins: [Mixin],
        data() {
            return {
                bg_width: 0,
                bg_height: 0,
                bg_prev_left: 0,
                bg_offset: {
                    left: 0,
                    top: 0
                }
            }
        },
        watch: {
            b_cars_car(value) {
                this.selectModel(value.name);
            }
        },
        computed: {
            c_bg_position() {
                const transform = this.device_platform === 'mobile' ?
                    'translateY(' + this.bg_offset.top + 'px)' :
                    'translateX(' + this.bg_offset.left + 'px)';
                return {
                    width: this.bg_width + 'px',
                    height: this.bg_height + 'px',
                    top: 0,
                    transform: transform
                };
            }
        },
        methods: {
            getBgSize() {
                let el = document.getElementById('sel-' + this.cur_value.toLowerCase());
                this.bg_width = el && el.offsetWidth;
                this.bg_height = el && el.offsetHeight;
                this.bg_prev_left = this.bg_offset.left;
                this.bg_offset = {
                    left: el.offsetLeft,
                    top: el.offsetTop
                };
            },
            selectModel(name) {
                this.$emit('selectModel', name);
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../styles/constants.scss";

    .tablet {
        .selector {
            font-size: 10px;
        }
    }

    .mobile {
        .selectors-bar {
            flex-direction: column;
        }

        .selector {
            width: 100%;
            height: 45px;
            font-size: 14px;
            padding: 0 10px 10px;
            max-width: 100%;

            &#sel-all {
                width: 100% !important;
            }

            &:last-child {
                margin-bottom: 10px;
            }
        }
    }

    .selectors-bar {
        align-self: flex-start;
        display: flex;
        // align-items: center;
        justify-content: flex-start;
        position: relative;
        background: #fff;
        width: 100%;
    }

    .selector {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        text-transform: uppercase;
        text-align: center;
        cursor: pointer;
        position: relative;
        transition: color .2s;
        padding: 0.9em;
        flex: 1;

        &#sel-all {
            // width: 180px;
        }

        &.active {
            color: white;
            background: $black;
        }
    }

</style>
