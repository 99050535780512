<template>
  <div class="block-choice-container" :class="[device_platform]">
		<div class="choice__container container">
      <div class="choice__title">
          КОМПЛЕКТАЦИИ
      </div>
				<selector-bar 
					:values="selectors"
					@selectModel="selectModel"
					:cur_value="cur_selector"
					:b_cars_car="b_cars_car"
				></selector-bar>
				
				<transition name="appear">
						<div class="black-bg"
									v-if="!['all','special'].some(el => el === cur_selector) || device_platform !== 'mobile'">
								<transition name="appear">
								</transition>
								<div 
									class="table-header"
									v-if="device_platform !== 'mobile'"
								>
										<div class="table-header__item img-column"></div>
										<div class="table-header__item comp-column">
												Комплектация
										</div>
										<div class="table-header__item year-column">
												Год
										</div>
										<div class="table-header__item code-column">
												Номер</div>
										<div class="table-header__item color-column">
												Цвет
										</div>
										<div class="table-header__item price-column">
												Цена
										</div>
										<div class="table-header__item btn-column choice__btn btn btn_primary">
											Получить лучшую цену
										</div>
								</div>
						</div>
				</transition>
				<div class="search-item"
							v-for="item in cars_to_show" :key="item.vin">
						<div class="image" >
								<img :src="item.images" @click="getCall({ type: 'sale', form:'complectations', button_text: 'ПОЛУЧИТЬ ЭТО ПРЕДЛОЖЕНИЕ'})">
						</div>

						<div class="model">
							{{item.modification}}<br>
							{{item.model}}
						</div>

						<div class="year">{{item.year}}</div>
						<div class="code">{{ item.vin }}</div>
						<div class="color">{{ item.color }}</div>

						<div 
							class="price" 
							@click="getCall"
						>
							{{ +item.price - item.max_discount | finance({dot:0}) }} ₽
						</div>

						<button 
							class="choice__btn btn btn_primary"
							@click="getCall"
						>
							Получить лучшую цену
						</button>
				</div>

				<div 
					class="btn btn_primary more"
					v-if="show_more_btn"
					@click="incCarsNum"
				>
					ПОКАЗАТЬ БОЛЬШЕ КОМПЛЕКТАЦИЙ
				</div>
		</div>
  </div>
</template>

<script>
import SelectorBar from './selector-bar';
import Mixin from '@/common/mixin';
import Finance from '../common/finance';
import CARS_DATA from '../common/data';
import axios from 'axios';

const COMMON_CARS_SELECTORS = [
	{ name: 'UNI-V' },
	{ name: 'UNI-T' },
	{ name: 'CS55PLUS', text: 'CS55<wbr>PLUS', },
	{ name: 'CS35PLUS', text: 'CS35<wbr>PLUS', },
	{ name: 'EADO PLUS', text: 'EADO<wbr>PLUS', },
	{ name: 'ALSVIN' },
	//{ name: 'CS75PLUS', text: 'CS75<wbr>PLUS', },
	{ name: 'LAMORE' },
	{ name: 'CS95' },
	//{ name: 'CS85', text: 'CS85<wbr>COUPE', },
  { name: 'UNI-K' },
  // { name: 'HUNTER' },
];
const ALL_CARS_SELECTOR = {
	name: 'all',
	text: 'ВСЕ МОДЕЛИ',
};

export default {
	name: 'block-choice',
	components: { SelectorBar },
	directives: {},
	mixins: [Mixin, Finance],
	props: ['b_cars_car'],
	data() {
		return {
			cars: [],
			cars_num: 6,
			cur_selector: 'all',
			cur_comp: '',
			cur_engine: '',
			dev_mode: false,
		};
	},
	async created() {
		if (this.dev_mode) {
			this.cars = CARS_DATA;
			this.cars = this.cars.map(car => {
				return {
					...car,
					image: car.images.split(',')[0]
				}
			});
		} else {
			// await axios
			// 	.get('/back/cars')
			// 	.then(res => {
			// 		console.log(this.res)
			// 		this.cars = res.data.models;
			// 		console.log(this.cars);
			// 	});

			this.cars = await fetch('/back/cars').then(response => response.json()).then(data => {return data});
		}
    // const compls = new Set( this.cars.map( item => item.model) )
    // console.log(compls);
    // console.log(this.cars);
  },
	computed: {
		selectors() {
			return [ALL_CARS_SELECTOR].concat(COMMON_CARS_SELECTORS);
		},
		prefiltered_cars() {
			if (this.cur_selector === 'all') {
				return this.cars;
			}

			if (this.cur_selector === 'CS35') {
				return this.cars
					.filter(car => car.model.includes(this.cur_selector))
					.filter(car => !car.model.includes('CS35PLUS'));
			}

			if (this.cur_selector === 'CS75FL') {
				return this.cars
					.filter(car => car.model.includes('CS75'));
			}
			return this.cars.filter(car => car.model.toUpperCase().includes(this.cur_selector));
		},
		filtered_cars() {
			let ret_cars = this.prefiltered_cars;
			if (this.cur_comp.length > 0) {
				ret_cars = ret_cars.filter(car => car.package === this.cur_comp);
      }
			if (typeof this.cur_engine === 'number' || this.cur_engine.length > 0) {
				ret_cars = ret_cars.filter(car => car.volume === this.cur_engine);
			}

			ret_cars.sort((a, b) => a.price - b.price);

			return ret_cars;
		},
		cars_to_show() {
			return this.filtered_cars.slice(0, this.cars_num);
		},
		show_more_btn() {
			return this.filtered_cars.length > this.cars_num;
		},
	},
	methods: {
		selectModel(name) {
			this.cars_num = 6;
			this.cur_comp = '';
			this.cur_engine = '';
			this.cur_selector = name;
		},
		setComp(comp) {
			this.cars_num = 6;
			this.cur_comp = comp;
		},
		setEngine(engine) {
			this.cars_num = 6;
			this.cur_engine = engine;
		},
		getName(car) {
			return car.modification.split(' ')[0];
		},
		getInfo(item) {
			let patrol = item.patrol ? item.patrol : '';
			return (
				this.getName(item).toUpperCase() +
				' ' +
				item.package +
				' ' +
				item.volume +
				'л. ' +
				patrol +
				', ' +
				item.hp +
				' л.с. ' +
				item.transm
			);
		},
		incCarsNum() {
			this.cars_num += 6;
		},
	},
};
</script>

<style scoped lang="scss">
@import '../styles/constants.scss';

.choice {

	&__container {
		display: flex;
		flex-direction: column;
	}

	&__title {
		font-size: 25px;
		margin-bottom: 56px;
	}

	&__btn {
		width: auto;
		font-size: 13px;
		flex: initial !important;
	}
}

.mobile {
	.search-item {
		flex-direction: column;
		height: auto;
		background: #efefef;
		margin: 0;
		margin: 10px 0px 0px;
	}

	.info,
	.color,
	.code,
	.price,
	.year {
		width: 100%;
		padding: 20px 0 20px 42%;
		font-size: 14px;
		background: white;
		position: relative;
		text-align: left;
		margin-bottom: 3px;
	}

	.model {
		font-weight: 300;
		font-size: 14px;
	}

	.image {
		background: white;
		width: 100%;
		height: 120px;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.info {
		white-space: normal;
	}

	.color {
		text-align: left;
		margin-right: 0;

		&.invisibile {
			height: 0;
			padding: 0;
		}
	}

	.offer-btn {
		margin: 10px 0;
		width: 275px;
	}

	.year {
		font-size: 14px;
	}
}

.tablet {

	.table-header {
		.table-header__item {
			text-align: center;
		}
	}

	.image {
		width: 130px;
		height: 70px;
		padding-left: 0px;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.model {
		font-size: 12px;
	}

	.color {
		font-size: 13px;
		text-align: center;
	}

	.price {
		font-size: 13px;
		text-align: center;
	}

}

.block-choice-container {
	width: 100%;
	padding-top: 30px;
	padding-bottom: 30px;
}

.dropdown-section {
	height: 90px;
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
}

.dropdown-box {
	margin-left: 20px;
}

.table-header {
	width: 100%;
	padding: 16px 10px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	transition: all 0.15s ease;

	.table-header__item {
		max-width: 410px;
		color: white;
		font-weight: 300;
		font-size: 12px;
		text-align: left;
		flex: 1;

		&.img-column {
			width: 130px;
			flex: initial;
		}

		&.comp-column {
			text-align: center;
		}

		&.year-column {
			text-align: center;
		}

		&.code-column {
			text-align: center;
		}

		&.price-column {
			text-align: center;
		}

		&.color-column {
			text-align: center;
		}

		&.btn-column {
			font-size: 13px;
			width: auto;
			opacity: 0;
			visibility: hidden;
		}
	}
}

.black-bg {
	background: $black;
}

.search-item {
	width: 100%;
	height: 110px;
	margin: 10px 0px 10px;
	padding: 0 10px;
	background: white;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	& > * {
		flex: 1;
	}
}

.model {
	font-size: 12px;
	font-weight: 700;
	text-align: center;
}

.image {
	display: flex;
	width: 130px;
	flex: initial;

	img {
		width: 100%;
		cursor: pointer;
	}
}

.year, .code, .color, .price {
	font-size: 12px;
	text-align: center;
}

.price {
	cursor: pointer;
}

.color {
	&.invisibile {
		visibility: hidden;
	}
}

.offer {
	cursor: pointer;
	color: #4ba82e;
	margin-top: 10px;
}

.offer-btn {
	width: 295px;
	height: 50px;
	text-align: center;
	background: #4ba82e;
	color: white;
	margin-right: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	cursor: pointer;

	&:hover:not(.disabled) {
		background: var(--button_common_hover);
	}
}

.more {
	align-self: center;
	margin: 20px auto 0;
}
</style>
