<template>
  <section class="block-menu">
    <div class="block-menu__container container">
      <div 
        class="block-menu__item"
        v-for="item in menu_list"
        :key="item.text"
        @click="goTo(item.link)"
      >
        {{ item.text }}
      </div>
    </div>
  </section>
</template>

<script>
import Mixin from '../common/mixin';

export default {
  name: 'block-menu',
  mixins: [Mixin],
  data() {
    return {
      menu_list: [
        {
          text: 'Преимущества',
          link: 'advantages'
        },
        {
          text: 'Модельный ряд',
          link: 'cars'
        },
        {
          text: 'КОНТАКТЫ',
          link: 'contacts'
        },
      ]
    }
  },
  methods: {
    hideMenu() {
      this.$emit('hideMenu');
    },
    goTo(href) {
      this.scrollTo(href);
      this.$emit('hideMenu');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/constants.scss";

.block-menu {
  background: $black;
  padding-top: 25px;
  padding-bottom: 25px;

  &__container {
    display: flex;
    justify-content: center;
  }

  &__item {
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 86px;
    }
  }
}

@media (max-width: 800px) {
  .block-menu {
    position: absolute;
    width: 100%;
    top: 100%;

    &__container {
      flex-direction: column;
      align-items: center;
    }

    &__item {
      text-align: center;

      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 22px;
      }
    }
  }
}

</style>