<template>
  <div class="car">
    <div class="car__main">
      <div class="car__head">
        <h3 class="car__name">Changan <br>{{ car.name }}</h3>

        <div class="car__head-right">
          <div v-if="car.images" class="car__gallery-btn" @click="openGallery(car)">фото из шоурума</div>
          <div v-if="car.new" class="car__name">NEW!</div>
        </div>

      </div>

      <img
        class="car__img"
        :src="getCarImg(car.id)" 
        :alt="'Фото модели ' + car.name"
      >
    </div>

    <div class="car__info">
      <div class="car__advs">
        <div class="car__adv car__adv_grey">
          <p class="car__adv-big-text">5</p>
          <div>
            <p>лет гарантии</p>
            <p><b>150 000 км</b></p>
          </div>
        </div>

        <div class="car__adv car__adv_grey">
          <p class="car__adv-big-text">1</p>
          <div>
            <p><b>покупка</b></p>
            <p><b>одним днём</b></p>
          </div>
        </div>

        <div 
          class="car__adv car__adv_black" 
        >
          <p class="car__adv-big-text">0</p>
          <div>
            <p>%</p>
            <p>Рассрочка</p>
          </div>
        </div>

        <div 
          class="car__adv car__adv_black" 
          v-if="car.advantage"
        >
          <div>
            <p><b>Выгода</b></p>
            <p>до {{ car.advantage | finance }} ₽</p>
          </div>
        </div>

        <div v-if="car.label" class="car__label">{{ car.label }}</div>
      </div>

      <div class="car__chars">
        <div
          class="car__char"
          v-for="char in car.chars"
          :key="char"
          v-html="char"
        ></div>
      </div>

      <div class="car__dop" v-if="car.dop">{{ car.dop }}</div>

      <div class="car__btns">
        <button v-if="car.btns[0]" class="car__btn btn btn_primary" @click="getCall">{{ car.btns[0] }}</button>
        <button v-if="car.btns[1]" class="car__btn btn btn_black" @click="getCall">{{ car.btns[1] }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from '../common/mixin';
import finance from '../common/finance';

export default {
  name: 'block-car',
  mixins: [Mixin, finance],
  props: ['car'],
  methods: {
    getCarImg(car) {
      return require('../images/cars/big/' + car + '.png');
    },
    openGallery(car) {
      this.$emit('openGallery', car);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/constants.scss";

.car {
  display: flex;
  justify-content: space-between;

  &__main {
    width: 100%;
    max-width: 705px;
    height: 400px;
    background: url('../images/cars/bg.jpg') no-repeat center/cover;
    padding: 38px 52px;
    position: relative;
    margin-right: 20px;
  }

  &__img {
    position: absolute;
    //top: 50%;
    bottom: -70px;
    width: 90%;
    right: 0;
  }

  &__head {
    color: #fff;
    display: flex;
    justify-content: space-between;
  }

  &__head-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__name {
    font-size: 58px;
    font-weight: 200;
    text-transform: uppercase;
  }

  &__label {
    background-color: #61666B;
    color: #fff;
    width: 100%;
    text-align: center;
    padding: 16px 20px;
    font-size: 26px;
    line-height: 26px;
    -moz-text-shadow:0 0 4px #fff; 
    -webkit-text-shadow:0 0 4px #fff; 
    text-shadow:0 0 4px #fff;
  }

  &__gallery-btn {
    font-weight: 200;
    font-size: 18px;
    text-decoration: underline;
    cursor: pointer;
  }

  &__info {
    display: flex;
    flex-direction: column;
    max-width: 420px;
  }

  &__advs {
    display: flex;
    flex-wrap: wrap;
    gap: 11px;
    font-weight: 200;
    margin-bottom: 47px;

    b {
      font-weight: 200;
    }
  }

  &__adv {
    color: $grey;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    flex: 1;
    cursor: pointer;
    
    &_grey {
      background: #61666B;
    }

    &_black {
      background: $black;
    }
  }

  &__adv-big-text {
    font-size: 57px;
    font-weight: 200;
    margin-right: 15px;
  }

  &__chars {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 21px;

    :deep(b) {
      font-weight: 200;
    }
  }

  &__char {
    font-size: 11px;
    font-weight: 200;
  }


  &__dop {
    font-weight: bold;
    font-size: 11px;
    text-align: center;
    margin-bottom: 42px;
  }

  &__btns {
    display: flex;
    flex-direction: column;
  }

  &__btn {
    font-weight: 200;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 160px;
  }

  &:nth-child(even) {
    flex-direction: row-reverse;

    .car {

      &__head {
        flex-direction: row-reverse;
      }

      &__name {
        text-align: right;
      }
    }
  }
}

@media (max-width: 800px) {
  .car {
    flex-direction: column;

    &__main {
      height: 193px;
      padding: 20px 10px;
      //margin-bottom: calc(100vw * 307 / 648 - 80px);
      margin-bottom: 80px;
      margin-right: 0;
    }

    &__img {
      min-width: 325px;
      bottom: -60px;
    }

    &__name {
      font-size: 25px;
    }

    &__gallery-btn {
      font-size: 13px;
    }

    &__info {
      padding: 0 13px;
      align-self: center;
    }

    &__label {
      font-size: 16px;
      padding: 12px 10px;
      width: 98%;
    }

    &__advs {
      gap: 7px;
      justify-content: center;
      margin-bottom: 16px;
    }

    &__adv {
      font-size: 12px;
      width: 48%;
      flex: initial;
      padding: 5px 10px;
    }

    &__adv-big-text {
      font-size: 40px;
      margin-right: 10px;
    }

    &__chars {
      margin-bottom: 16px;
    }

    &__char {
      font-size: 10px;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &__dop {
      margin-bottom: 16px;
    }

    &__btn {

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 35px;
    }

    &:nth-child(even) {
      flex-direction: column;

      .car {

        &__head {
          flex-direction: initial;
        }

        &__name {
          text-align: initial;
        }
      }
    }
  }
}
</style>