import Vue from 'vue';
import App from './App.vue';
import Constants from './common/constants';
import "./styles/main.scss";

import 'video.js/dist/video-js.css';
import VueVideoPlayer from 'vue-video-player';

// Tell Vue to use the plugin
Vue.prototype.CONSTANTS = Constants;
Vue.config.productionTip = false;
Vue.use(VueVideoPlayer);

new Vue({
    render: h => h(App)
}).$mount("#app");

