<template>
  <section class="advantages">
    <div class="advantages__container container">
      <div 
        class="advantages__item"
        v-for="adv in advantages"
        :key="adv.icon"
        @click="getCall"
      >
        <div class="advantages__item-icon" :class="'advantages__item-icon_' + adv.icon"></div>

        <div 
          class="advantages__item-text" 
          v-html="adv.text"
        ></div>
      </div>
    </div>
  </section>
</template>

<script>
import Mixin from '../common/mixin';

export default {
  name: 'block-advantages',
  mixins: [Mixin],
  data() {
    return {
      advantages: [
        {
          icon: 'n1',
          text: '<b>Дилер №1 в Москве</b> <br> по итогам 2022 года'
        },
        {
          icon: 'car',
          text: '<b>Более 150 автомобилей</b> <br>в наличии с ПТС'
        },
        {
          icon: 'trade-in',
          text: '<b>Trade-in</b> <br> с выкупом по рынку'
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/constants.scss";

.advantages {
  width: 100%;
  padding: 92px 0;

  &__container {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      .advantages__item-icon {
        background: $black;

        &::before {
          filter: invert(100%);
        }
      }
    }
  }

  &__item-icon {
    border: 2px solid $black;
    width: 106px;
    height: 106px;
    position: relative;
    margin-right: 25px;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-repeat: no-repeat;
      background-position: center;
    }

    @each $icon in 'n1', 'car', 'trade-in' {
      &_#{$icon} {
        &::before {
          background-image: url('../images/advantages/#{$icon}.svg');
        }
      }
    }

    &_car::before {
      background-position: left center;
    }

    &_trade-in::before {
      background-position: top center;
    }
  }

  &__item-text {
    font-size: 17px;
  }
}

@media (max-width: 800px) {
  .advantages {
    padding: 28px 0;
    display: flex;
    justify-content: center;

    &__container {
      flex-direction: column;
      flex-wrap: wrap;
      width: auto;
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: 19px;
      }
    }

    &__item-icon {
      width: 60px;
      height: 60px;
      margin-right: 14px;

      &::before {
        background-size: 90%;
      }

      &_n1::before {
        background-size: 70%;
      }

      &_trade-in::before {
        background-size: 100%;
      }

    }

    &__item-text {
      font-size: 10px;
    }
  }
}

</style>