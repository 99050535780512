<template>
  <div class="block-header" :class="{scrolled}" id="header">
    <div class="block-header__container container">
      <div class="block-header__logo logo"></div>

      <div class="block-header__dealer-address">
        <img 
          class="block-header__dealer"
          src="../images/logos/dealer.svg"
        >
        <div class="block-header__address">{{ CONSTANTS.offices[0].address }}</div>        
      </div>


      <a
        id="phone"
        class="block-header__phone phone" 
        :href="'tel:' + CONSTANTS.phone_raw"
      >{{ CONSTANTS.phone }}</a>

      <button 
        class="block-header__btn btn btn_white"
        @click="getCall"
      >Заказать звонок</button>

      <div 
        class="block-header__burger" 
        :class="{expanded}"
        @click="expanded = !expanded"
      ></div>
    </div>
    
    <block-menu 
      v-if="menu" 
      @hideMenu="expanded = false"
      @scrollTo="scrollTo"
    ></block-menu>

    <!-- <div class="block-header__bottom container">
      <p class="block-header__text"><strong>График работы в Новогодние праздники:</strong></p>
      <p class="block-header__text">31 декабря 2023 до <strong>18:00</strong>, 01 января 2024 – <strong>ВЫХОДНОЙ ДЕНЬ</strong>, С 02 января 2024 – <strong>в обычном режиме</strong></p>
    </div> -->
  </div>
</template>

<script>
import Mixin from "../common/mixin";
import BlockMenu from './block-menu.vue';

export default {
  name: "block-header",
  mixins: [Mixin],
  components: { BlockMenu },
  data() {
    return {
      offices: this.CONSTANTS.offices,
      scrolled: 0,
      expanded: false,
    };
  },
  computed: {
    menu() {
      return window.innerWidth < 801 && this.expanded; 
    }
  },
  mounted() {
    document.addEventListener('scroll', e => {
      this.scrolled = window.scrollY;
    })
  }
};
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";

.block-header {
  color: #fff;
  background: #000;
  width: 100%;
  position: fixed;
  z-index: 100;
  padding: 38px 0;
  border-bottom: 1px solid #fff;
  transition: background 200ms linear;

  &.scrolled {
    background: #000;
  }

  &__container {
    display: flex;
    align-items: center;
  }

  &__logo {
    width: 242px;
    height: 33px;
  }

  &__dealer-address {
    margin-left: 20px;
    padding-left: 20px;
    border-left: 2px solid #fff;
    margin-right: auto;
  }

  &__dealer {
    width: 250px;
  }

  &__address {
    font-size: 15px;
  }

  &__phone {
    font-size: 15px;
    margin-right: 33px;
  }

  &__btn {
    text-transform: uppercase;
  }

  &__burger {
    display: none;
  }

  // &__bottom {
  //   margin-top: 10px;
  //   text-align: center;
  // }
}

@media (max-width: 800px) {
  .block-header {
    padding: 12px 0;
    background: linear-gradient(180deg, rgba(36, 43, 49, 0.82) 35.42%, rgba(35, 47, 54, 0) 100%);

    &__logo {
      width: 105px;
      height: 15px;
    }

    &__dealer-address {
      margin-left: 9px;
      padding-left: 9px;
    }

    &__dealer {
      width: 108px;
    }

    &__address {
      font-size: 12px;
      max-width: 100px;
    }

    &__phone {
      display: none;
    }

    &__btn {
      display: none;
    }

    &__burger {
      display: flex;
      width: 23px;
      height: 17px;
      background: url('../images/utils/burger.svg') no-repeat center/contain;

      &.expanded {
        background-image: url('../images/utils/close_white.svg');
      }
    }

    // &__text {
    //   font-size: 12px;
    // }
  }
}
</style>
