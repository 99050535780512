<template>
  <div id="app" :class="{ webp: is_webp }">
    <block-header
      @callBack="callThere"
      @scrollTo="scrollTo"
      @getCall="handleGetCall"
    />
    <block-main
      @callBack="callThere"
      @getCall="handleGetCall"
      @getAgreement="getAgreement"
    />
    <block-offer
      id="offer"
      @callBack="callThere"
      @getCall="handleGetCall"
      @getAgreement="getAgreement"
    />
    <!-- <block-clock  @callBack="callThere"
                      @getCall="handleGetCall"
                      @getAgreement="getAgreement"
    id="desktop-timer"></block-clock>-->
    <block-advantages
      id="advantages"
      @callBack="callThere"
      @getCall="handleGetCall"
      @getAgreement="getAgreement"
    />
    <block-cars
      id="cars"
      @callBack="callThere"
      @getCall="handleGetCall"
      @getAgreement="getAgreement"
      @scrollTo="scrollTo"
      @bCarsClicked="setChoice"
    />
    <block-choice
      id="choice"
      v-if="device_platform !== 'mobile'"
      @callBack="callThere"
      @getCall="handleGetCall"
      @getAgreement="getAgreement"
      @scrollTo="scrollTo"
      :b_cars_car="b_cars_car"
    />
    <!-- <video-container :webp="is_webp"></video-container> -->

    <block-contacts
      id="contacts"
      @getAgreement="getAgreement"
      @callBack="callThere"
      @getCall="handleGetCall"
    />
    <block-footer />
    <full-get
      v-show="show.get"
      :data="data"
      @close="closeThings"
      @callBack="callThere"
      @getAgreement="getAgreement"
    />
    <full-thanks v-if="show.thanks" @close="closeThings" />
    <full-agreement
      v-if="show.agreement"
      @close="closeAgreement"
    />
  </div>
</template>

<script>
import BlockHeader from "./components/block-header";
import BlockMain from "./components/block-main";
import BlockAdvantages from "./components/block-advantages";
import BlockClock from "./components/block-clock";
import BlockCars from "./components/block-cars";
import BlockChoice from "./components/block-choice";
import BlockContacts from './components/block-contacts.vue';
import BlockMap from "./components/block-map";
import BlockOffer from "./components/block-offer";
import BlockFooter from "./components/block-footer";
import FullGet from "./components/full-get";
import FullThanks from "./components/full-thanks";
import FullAgreement from "./components/full-agreement";
import Mixin from "./common/mixin";

import videoContainer from "./components/video-container";
import "video.js/dist/video-js.css";

import axios from "axios";
import Send from "./common/send";
import $ from "jquery";
import smoothScrollTo from "./common/smoothScrollTo";
import sendToMetrics from "@/common/sendToMetrics";

export default {
  name: "app",
  components: {
    FullAgreement,
    BlockHeader,
    BlockCars,
    BlockClock,
    BlockChoice,
    BlockFooter,
    BlockContacts,
    BlockMap,
    BlockMain,
    BlockAdvantages,
    FullGet,
    BlockOffer,
    FullThanks,
    videoContainer,
  },
  mixins: [Mixin],
    data: function () {
    return {
      show: {
        agreement: false,
        load: false,
        get: false,
        thanks: false,
      },
      data: {},
      b_cars_car: null,
      is_webp: true,
    };
  },
  mounted() {
    $("html").on("keyup", (event) => {
      if (event.keyCode === 27) {
        this.closeThings();
      }
    });
    this.canUseWebP();
    history.scrollRestoration = "manual";
    let hash = window.location.hash.replace("#", "");
    if (hash) {
      this.scrollTo(hash);
    }
  },
  methods: {
    canUseWebP() {
      let elem = document.createElement("canvas");

      if (!!(elem.getContext && elem.getContext("2d"))) {
        // was able or not to get WebP representation
        return (this.is_webp =
          elem.toDataURL("image/webp").indexOf("data:image/webp") == 0);
      }

      // very old browser like IE 8, canvas not supported
      return (this.is_webp = false);
    },
    closeThings() {
      this.show.get = false;
      this.show.thanks = false;
      this.show.agreement = false;
      this.data = {};
      this.unblockScroll();
    },
    closeAgreement() {
      this.show.agreement = false;
      if (!this.show.get) {
        this.unblockScroll();
      }
    },
    getAgreement() {
      this.show.agreement = true;
      this.blockScroll();
    },
    yaMetricaRequest(event) {
      if (
        window.yaCounter56122639 &&
        typeof window.yaCounter56122639.reachGoal === "function"
      ) {
        window.yaCounter56122639.reachGoal(event);
      } else {
        console.log("no yaCounter56122639 was found");
      }
    },
    handleGetCall(data) {
      let phone_raw = document
        .getElementById("phone")
        .getAttribute("href")
        .slice(4);
      let phone = document.getElementById("phone").innerText;


      // this.yaMetricaRequest("click");
      sendToMetrics('open_form')

      this.show.get = true;
      this.data = {
        phone: phone,
        phone_raw: phone_raw,
        ...data,
      };
      this.blockScroll();
    },
    scrollTo(where) {
      const header_height = document.getElementById('header').offsetHeight;
      
      let target = document.getElementById(where);

      smoothScrollTo(
        $("#" + where).offset().top - header_height
      );
    },
    setChoice(car) {
      this.b_cars_car = car;
    },
    callThere(event) {
      let cKeeper = this.CONSTANTS.cKeeper,
        manager_phone = this.CONSTANTS.need_manager_phone
          ? $("#phone")
              .text()
              .replace(/\s/g, "\u00A0")
              .replace(/-/g, "\u2011")
              .replace(/\D+/g, "")
          : "",
        req = Send(event, this.data, cKeeper, manager_phone);
      this.show.load = true;
      // this.yaMetricaRequest("callback");
      sendToMetrics('send_form')

      if (cKeeper === "actioncall") {
        const finishCall = () => {
          this.closeThings();
          this.show.load = false;
          this.show.thanks = true;
          if (typeof window.dataLayer !== "undefined") {
            window.dataLayer.push({
              event: "callkeeper-call_order-ckaction",
              eventCategory: "callkeeper",
              eventAction: "call_order",
              eventLabel: "ckaction",
            });
          }
          console.log("done");
        };

        if (!req) {
          finishCall();
        } else {
          req.finally(finishCall);
        }
      } else {
        this.closeThings();
        this.show.load = false;
        this.show.thanks = true;
      }
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import "./styles/constants.scss";

#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $grey;
}

@media (max-width: 1600px) {
  #desktop-timer {
    display: none;
  }
}
</style>
