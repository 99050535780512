<template>
  <section class="contacts">
		<div class="contacts__main">
			<div class="contacts__container container">
				<div class="contacts__info">
					<h2 class="contacts__title">Контакты</h2>
					<p><b>Адрес:</b> {{ CONSTANTS.offices[0].address }}</p>
					<p><b>Отдел продаж:</b> {{ CONSTANTS.offices[0].worktime }}</p>
					<p><b>Телефон:</b> <a class="phone" :href="'tel:' + CONSTANTS.phone_raw"> {{ CONSTANTS.phone }}</a></p>
				</div>

				<callback-form
					:button_class="'btn_white'"
					:horizontal="true"
					:checkbox_white="true"
					@callBack="handleCallBack"
				>Оставить заявку</callback-form>
			</div>
		</div>

		<observer @display="waitYmaps"></observer>
		<div id="ya-karto"></div>
  </section>
</template>

<script>
import CallbackForm from './callback-form/callback-input.vue';
import Mask from '../common/mask';
import Mixin from '../common/mixin';
import Keyup from '../common/keyup';
import Observer from './observer';

export default {
		name: 'block-contacts',
		components: {CallbackForm, Observer},
		directives: {Mask, Keyup},
		mixins: [Mixin],
		props: [''],
		data() {
			return {
				offices: this.CONSTANTS.offices
			}
		},
		// mounted() {
		//     setTimeout(this.waitYmaps, 5000);
		// },
		methods: {
				initYandexMap() {
						const isMobile = {
								Android: function () {
										return navigator.userAgent.match(/Android/i);
								},
								BlackBerry: function () {
										return navigator.userAgent.match(/BlackBerry/i);
								},
								iOS: function () {
										return navigator.userAgent.match(/iPhone|iPad|iPod/i);
								},
								Opera: function () {
										return navigator.userAgent.match(/Opera Mini/i);
								},
								Windows: function () {
										return navigator.userAgent.match(/IEMobile/i);
								},
								any: function () {
										return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
								}
						};
						let zoomControl = new ymaps.control.ZoomControl({
								options: {
										position: {
												left: 'auto',
												right: 10,
												top: 108,
										}
								}
						});

						let center_x = this.CONSTANTS.center_coords.x, center_y = this.CONSTANTS.center_coords.y;

						if (this.device_platform === 'tablet') {
								center_x = center_x + 0.003;
								center_y = center_y;
						} else {
								center_x = center_x - 0.005;
								center_y = center_y;
						}

						let myMap = new ymaps.Map("ya-karto", {
								center: [center_y, center_x],
								zoom: 16,
								controls: []
						}, {
								searchControlProvider: 'yandex#search'
						});
						myMap.controls.add(zoomControl);

						this.CONSTANTS.offices_coords.forEach((point, i) => {
								let office_name = this.offices[i].name;
								myMap.geoObjects
										.add(new ymaps.Placemark([point.y, point.x], {
												iconContent: office_name,
										}, {
												preset: "islands#blueStretchyIcon",
												iconColor: '#1e98ff'
										}));
						})
						;
						if (isMobile.any() || this.device_platform !== 'desktop') {
								myMap.behaviors.disable('drag');
						}
						myMap.behaviors.disable('scrollZoom');
				},
				waitYmaps() {
						let script = document.createElement('script');
						script.src = '//api-maps.yandex.ru/2.1/?load=package.standard&lang=ru_RU';
						document.head.appendChild(script);
						script.onload = () => {
								ymaps.ready(this.initYandexMap);
						};
				}
		}
}
</script>

<style lang="scss" scoped>
@import "../styles/constants.scss";

#ya-karto {
	width: 100%;
	height: 800px;
}


.contacts {
	width: 100%;

	&__main {
		color: #fff;
		background: url('../images/new-bg/offer1.png') no-repeat left/contain, url('../images/new-bg/offer2.png') no-repeat right/contain;
		background-color: $black;
		width: 100%;
		padding: 47px 0;
	}

	&__container {
		display: flex;
		align-items: center;
	}

	&__title {
		font-weight: bold;
		font-size: 25px;
		text-transform: uppercase;
		margin-bottom: 60px;
	}

	&__info {
		font-size: 15px;
		margin-right: auto;

		p {
			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}
	}
}

@media (max-width: 800px) {
	#ya-karto {
		height: 350px; 
	}

	.contacts {

		&__main {
			padding: 30px 0;
			background: $black;
		}

		&__container {
			flex-direction: column;
			align-items: stretch;
		}

		&__title {
			font-size: 15px;
			margin-bottom: 27px;
		}

		&__info {
			font-size: 12px;
			margin-bottom: 42px;

			p {
				&:not(:last-child) {
					margin-bottom: 13px;
				}
			}
		}
	}
}
</style>