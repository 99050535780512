<template>
    <div class="block-contacts-container" :class="device_platform">
        <div class="header-text">
            КОНТАКТЫ
        </div>
        <observer @display="waitYmaps"></observer>
        <div id="ya-karto">
            <div class="input-wrapper">
                <div class="input-container">
                    <div class="logos-container">
                        <div class="logo"></div>
                        <div class="office-name-img"></div>
                    </div>
                    <div class="offices-container">
                        <div class="office-container" v-for="office in offices">
                            <div class="worktime-container">
                                <div class="worktime-text">Отдел продаж: </div>
                                <div class="worktime-value" v-html="office.worktime"></div>
                            </div>
                            <div class="office-phone">
                                <a :href="'tel:' + office.phone_raw" class="phone" id="phone">
                                    {{office.phone}}
                                </a>
                            </div>
                        </div>
                    </div>
                    <callback-input v-on="$listeners" :hide_agreement="true" :hide_name="true" :data="{type: 'sale', form: 'contacts'}">ОСТАВИТЬ ЗАЯВКУ</callback-input>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CallbackInput from './callback-form/callback-input';
    import Mask from '../common/mask';
    import Mixin from '../common/mixin';
    import Keyup from '../common/keyup';
    import Observer from './observer';

    export default {
        name: '',
        components: {CallbackInput, Observer},
        directives: {Mask, Keyup},
        mixins: [Mixin],
        props: [''],
        data() {
            return {
                offices: this.CONSTANTS.offices
            }
        },
        // mounted() {
        //     setTimeout(this.waitYmaps, 5000);
        // },
        computed: {},
        methods: {
            initYandexMap() {
                const isMobile = {
                    Android: function () {
                        return navigator.userAgent.match(/Android/i);
                    },
                    BlackBerry: function () {
                        return navigator.userAgent.match(/BlackBerry/i);
                    },
                    iOS: function () {
                        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
                    },
                    Opera: function () {
                        return navigator.userAgent.match(/Opera Mini/i);
                    },
                    Windows: function () {
                        return navigator.userAgent.match(/IEMobile/i);
                    },
                    any: function () {
                        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
                    }
                };
                let zoomControl = new ymaps.control.ZoomControl({
                    options: {
                        position: {
                            left: 'auto',
                            right: 10,
                            top: 108,
                        }
                    }
                });

                let center_x = this.CONSTANTS.center_coords.x, center_y = this.CONSTANTS.center_coords.y;

                if (this.device_platform === 'tablet') {
                    center_x = center_x + 0.003;
                    center_y = center_y;
                } else {
                    center_x = center_x - 0.005;
                    center_y = center_y;
                }

                let myMap = new ymaps.Map("ya-karto", {
                    center: [center_y, center_x],
                    zoom: 16,
                    controls: []
                }, {
                    searchControlProvider: 'yandex#search'
                });
                myMap.controls.add(zoomControl);

                this.CONSTANTS.offices_coords.forEach((point, i) => {
                    let office_name = this.offices[i].name;
                    myMap.geoObjects
                        .add(new ymaps.Placemark([point.y, point.x], {
                            iconContent: office_name,
                        }, {
                            preset: "islands#blueStretchyIcon",
                            iconColor: '#1e98ff'
                        }));
                })
                ;
                if (isMobile.any() || this.device_platform !== 'desktop') {
                    myMap.behaviors.disable('drag');
                }
                myMap.behaviors.disable('scrollZoom');
            },
            waitYmaps() {
                let script = document.createElement('script');
                script.src = '//api-maps.yandex.ru/2.1/?load=package.standard&lang=ru_RU';
                document.head.appendChild(script);
                script.onload = () => {
                    ymaps.ready(this.initYandexMap);
                };
            }
        }
    }
</script>

<style scoped lang="scss">
    .mobile {
        &.block-contacts-container {
            height: 920px;
            .header-text {

            }

            #ya-karto {
                position: absolute;
                bottom: 0;

                .input-wrapper {
                    height: 271px;
                    bottom: 610px;
                    .input-container {
                        width: 300px;
                        right: initial;
                        left: calc(50% - 150px);
                        padding: 10px 10px 20px;
                        .logos-container {

                            .logo {

                            }

                            .office-name-img {

                            }
                        }

                        .offices-container {

                            .office-container {

                                .worktime-container {

                                    .worktime-text {
                                        font-size: 10pt;
                                    }

                                    .worktime-value {
                                        font-size: 10pt;
                                    }

                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .tablet {
        &.block-contacts-container {

            .header-text {

            }

            #ya-karto {

                .input-wrapper {

                    .input-container {
                        top:50px;
                        right: 50px;
                        .logos-container {

                            .logo {

                            }

                            .office-name-img {

                            }
                        }

                        .offices-container {

                            .office-container {

                                .worktime-container {

                                    .worktime-text {

                                    }

                                    .worktime-value {

                                    }

                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .block-contacts-container {
        width: 100%;
        position: relative;
        margin-top: 30px;
        .header-text {
            margin-bottom: 50px;
        }

        #ya-karto {
            width: 100%;
            height: 540px;
            position: relative;
        }

        .input-wrapper {
            width: 100%;
            max-width: 1920px;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            height: 540px;
            z-index: 3;

            .input-container {
               position: absolute;
                top: 50px;
                right: 120px;
                background: #FCFCFC;
                width: 400px;
                padding: 20px 40px 50px;


                .logos-container {
                    display: flex;
                    justify-content: space-around;
                    .logo {
                        background: url('../images/logos/logo.svg') no-repeat center;
                        background-size: contain;
                        width: 100px;
                        height: 100px;
                    }

                    .office-name-img {
                        width: 150px;
                        height: 100px;
                        background: url('../images/logos/office_name.png') no-repeat center;
                        background-size: contain;
                    }
                }

                .offices-container {
                    display: flex;
                    flex-direction: column;
                    align-items:center;

                    .office-container {

                        .worktime-container {
                            display: flex;
                            margin-top: 20px;
                            margin-bottom: 10px;
                            .worktime-text {
                                font-size: 10pt;
                                font-weight: 600;
                                margin-right: 5px;
                            }

                            .worktime-value {
                                font-size: 10pt;
                            }

                        }

                        .office-phone {
                            font-weight: bold;
                            font-size: 13pt;
                            text-align: center;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }

    }
</style>
