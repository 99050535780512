<template>
  <form
    class="callback-form callbackwidget-call-form"
    novalidate
    @submit.prevent="submitThis"
    :class="[device_platform, { horizontal }, form_class]"
  >
    <div class="callback-form__container">
      <div class="callback-form__input-container">
        <input
          name="tel"
          type="tel"
          class="callback-form__input"
          placeholder="Телефон"
          v-model="phone"
          ref="phone"
          v-mask
          :pattern="'.*[0-9]{1}.*[0-9]{3}.*[0-9]{3}.*[0-9]{2}.*[0-9]{2}'"
          required
        />

        <checkbox 
          class="callback-form__checkbox" 
          :value="acceptance" 
          @checkboxChange="acceptance = !acceptance"
          :white="checkbox_white"
        >
          <div class="agreement" v-if="CONSTANTS.need_agreement">
            <span class="agreement__text">
              Согласие на обработку персональных данных
            </span>
          </div>
        </checkbox>
      </div>

      <button
        type="submit"
        :disabled="!valid"
        class="btn callback-form__button"
        :class="[{ button_disabled: !valid }, buttonClass]"
        ref="submitter"
      >
        <slot></slot>
      </button>
    </div>

  </form>
</template>

<script>
import Mixin from "../../common/mixin";
import Mask from "../../common/mask";
import Checkbox from "../checkbox.vue";

export default {
  name: "callback-input",
  components: { Checkbox },
  directives: { Mask },
  mixins: [Mixin],
  emits: ["callBack"],
  props: ["horizontal", "data", "form_class", "focusOnMount", "button_class", "checkbox_white"],
  data() {
    return {
      acceptance: true,
      phone: "",
    };
  },
  computed: {
    agreed() {
      return this.acceptance;
    },
    valid() {
      let phone = this.phone.replace(/\D+/g, "");
      return this.agreed && phone.length === 11;
    },
    buttonClass() {
      return this.button_class || "btn_primary";
    },
  },
  mounted() {
    if (this.focusOnMount) {
      this.$refs.phone.focus();
    }
  },
  methods: {
    getAgreement() {
      this.$emit("getAgreement");
    },
    submitThis(event) {
      if (
        this.$refs.phone.validity.valueMissing ||
        !this.$refs.phone.validity.valid
      ) {
        this.$refs.phone.setCustomValidity("Введите номер телефона");
      }
      if (
        !this.$refs.phone.validity.valueMissing &&
        !this.$refs.phone.validity.patternMismatch
      ) {
        this.$refs.phone.setCustomValidity("");
      }
      if (this.agreed && this.$el.reportValidity()) {
        if (typeof ckForms !== "undefined") {
          ckForms.send(this.$el);
        }

        this.$emit("callBack", {
          phone: this.phone,
          data: this.data,
        });

      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/constants.scss";


.callback-form {

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 auto;
    max-width: 100%;
    width: 100%;
  }

  &__input {
    width: 100%;
    height: 42px;
    margin-bottom: 17px;
    font-size: 15px;
    color: inherit;
    background: none;
    border-bottom: 1.2px solid;

    &:focus {
      outline: none;
    }

    &::placeholder {
      font-weight: 400;
      font-size: inherit;
      color: inherit;
      opacity: .7;
    }
  }

  &__input-container {
    margin-bottom: 19px;
  }

  &__button {
    width: 100%;
  }

  &.horizontal {
    .callback-form {
      &__container {
        flex-direction: row;
        align-items: center;
      }

      &__input-container {
        width: 343px;
        margin-right: 100px;
      }

      &__button {
        width: auto;
        min-width: 315px;
      }
    }
  }
}

.agreement {
  color: inherit;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;

  &__text {
    font-size: 9px;
  }
}

@media (max-width: 800px) {
  .callback-form {
    
    &__input {
      height: 34px;
      margin-bottom: 13px;
      font-size: 13px;
    }

    &.horizontal {
      .callback-form {
        &__container {
          flex-direction: column;
        }

        &__input-container {
          width: 100%;
          margin-right: 0;
          margin-bottom: 30px;
        }

        &__button {
          width: 100%;
          min-width: unset;
        }
      }
    }
  }

  .agreement {
    &__text {
      font-size: 10px;
    }
  }
}
</style>
