import CF from './common-functions';
import CarsInfo from './cars-info';

const prices = CarsInfo.map(el => el.price);
const advantages = CarsInfo.map(el => el.advantage);
const payments = CarsInfo.map(el => el.payment);
const MAX_ADVANTAGE = Math.max.apply(null, advantages);
const MIN_PRICE = Math.min.apply(null, prices);
const MIN_PAYMENT = Math.min.apply(null, payments);

let today = new Date();
let days_total = Math.floor((new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)).getTime() / (1000 * 60 * 60 * 24));

let date = new Date(2022, 0, 30, 21);

while(today > date) {
    date = date.valueOf() + 8.64e+7 * 7;
    date = new Date(date); 
}

// let date = CF.getNewDate();

const PHONE = '+7 (495) 933-40-33'.replace(/\s/g, '\u00A0').replace(/-/g, '\u2011');

const PHONE_RAW = '+'  + PHONE.replace(/\D+/g, "");


export default {
    phone: PHONE,
    phone_raw: PHONE_RAW,
    end_date: date,
    max_advantage: MAX_ADVANTAGE,
    min_price: MIN_PRICE,
    min_payment: MIN_PAYMENT,
    offices: [
        {
            name: '',
            address: 'Москва, ул. Горбунова, 14',
            phone: PHONE,
            phone_raw: PHONE_RAW,
            worktime: 'пн-вс 8:30 до 21:00',
        },
    ],
    brand: 'Чанган',
    offices_coords: [
        {
            x: 37.374102,
            y: 55.725929
        },
    ],
    center_coords: {
        x: 37.374102 + 0.005,
        y: 55.725929
    },
    need_agreement: true,
    // cKeeper: undefined,
    cKeeper: 'comagic',
    need_manager_phone: true
}
