<template>
	<div class="block-get get modal">
		<div class="blacking" @click="hideSelf"></div>
		<div class="get__container">
			<div class="get__body">
				<img
					src="../images/utils/close.svg"
					alt="Закрыть"
					class="get__close"
					width="24"
					height="24"
					@click="hideSelf"
				/>
				<h3 class="get__title" v-html="data.title ? data.title : 'Узнать персональную выгоду на changan'"></h3>
				<p class="get__phone-wrapper">
					Звоните
					<a class="phone" :href="'tel:' + CONSTANTS.phone_raw">
						{{ CONSTANTS.phone }}
					</a>
				</p>
				<p class="get__text">Или оставте заявку на обратный звонок</p>
				<callback-input
					v-on="$listeners"
					:form_class="'popup'"
					:focusOnMount="true"
					:data="data"
				>
					{{data.button ? data.button : 'Получить предложение'}}
				</callback-input>
			</div>

		</div>
	</div>
</template>

<script>
import CallbackInput from "./callback-form/callback-input.vue";
import Mixin from "../common/mixin";

export default {
	name: "full-get",
	components: { CallbackInput },
	emits: ['close'],
	mixins: [Mixin],
	props: ["data"],
	data() {
		return {
			phone: "",
			defaultImg: 'contacts',
		};
	},
	computed: {
	},
	methods: {
		hideSelf() {
			this.$emit("close");
		},
		getAgreement() {
			this.$emit("getAgreement");
		},
	},
};
</script>

<style lang="scss">
@import "../styles/constants.scss";

.get {
	width: 100%;
	display: flex;

	&__container {
		position: fixed;
		top: 50vh;
		left: 50vw;
		transform: translate(-50%, -50%);
		width: 100%;
		max-width: 95vw;
		display: flex;
		justify-content: center;
		z-index: 500;
		pointer-events: none;
	}

	&__body {
		color: $black;
		position: relative;
		max-width: 435px;
		width: 100%;
		padding: 42px 53px 32px;
		background: #EAEAEA url('../images/new-bg/get.png') no-repeat left bottom/contain;
		pointer-events: all;
		text-align: center;
	}

	&__title {
		font-size: 20px;
    margin-bottom: 45px;
    text-transform: uppercase;
	}

	&__phone-wrapper {
		margin-bottom: 10px;
	}

	&__text {
		margin-bottom: 15px;
	}

	&__image {
		pointer-events: all;
		display: flex;
	}

	&__close {
		position: absolute;
		right: 12px;
		top: 12px;
    width: 13px;
    height: 13px;
		cursor: pointer;
	}
}

@media (max-width: 800px) {
	.get {
		&__body {
			background: $grey;
			padding: 35px 15px;
		}

		&__title {
			font-size: 17px;
			margin-bottom: 20px;
		}
	}
}

</style>
